<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

const props = defineProps<{
  points: number
}>()

const pointsFull = computed(() => Math.ceil(props.points))
const pointsEmpty = computed(() => 5 - pointsFull.value)
</script>

<template>
  <div class="flex items-center">
    <Icon
      v-for="point in pointsFull"
      :key="`heart-full-${point}`"
      graphic="heart-full"
      size="md"
      class="text-txt-400"
    />

    <Icon
      v-for="point in pointsEmpty"
      :key="`heart-${point}`"
      graphic="heart"
      size="md"
      class="text-txt-400"
    />
  </div>
</template>
